import gql from "graphql-tag";

export const FindMembershipQuery = gql`
query FindMembership($email: String!) {
  memberships: memberships(where: {OR: [
      {email: $email}
      {secondaryEmail_contains: $email}
      ], season: "2022-2023"}) {
    id
    name
    firstName
    email
    phone
  }
}
`;

export const FindClientQuery = gql`
query FindClient($email: String!) {
  clients(where: {OR: [
      {email: $email}
      {secondaryEmail_contains: $email}
      ]}) {
    id
    name
    firstName
    email
    phone
  }
}
`;

export const CreateOogstfeestRegistrationMutation = gql`
mutation CreateOogstfeestRegistration($data: OogstfeestRegistrationCreateInput!) {
  createOogstfeestRegistration(
    data: $data
  ) {
    id
  }
}`;
