import React, { useEffect, useState } from 'react';
import { FormSection, FormWrapper } from "../../shared/components/soft-farms-forms";
import FormInput from "../../shared/components/Form/FormInput";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { validationSchema } from "./Oogstfeest.validationSchema";
import { InputNumber } from "primereact/inputnumber";
import FormCheckbox from "../../shared/components/Form/FormCheckbox";
import styled from "styled-components";
import classNames from "classnames";
import { oogstfeestOptions } from "../../shared/config.oogstfeest";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { CreateOogstfeestRegistrationMutation, FindClientQuery } from "./SignupForm.gql";
import { CreateOogstfeestRegistration, CreateOogstfeestRegistrationVariables, FindClient } from "../../__generated__/types";
import { first } from "lodash";
import { formatCurrency } from "../../../src/shared/utils/currency.utils";
import { SelectButton } from "primereact/selectbutton";
import { StringParam, useQueryParam } from "use-query-params";
import emailjs from "emailjs-com";

interface SignupFormProps {
  onHide: (registrationComplete: boolean, error?: string) => void;
}

interface SignupData {
  email?: string | undefined | null;
  name?: string;
  firstName?: string;
  phone?: string;
  member: boolean;
  membershipId?: string;
  buffet: boolean;
  buffetSlot: number;
  buffetNrOfAdults: number;
  buffetNrOfChildren: number;
  buffetNrOfChildrenFree: number;
  workshopChildren: boolean;
  workshopChildrenNrOf: number;
  workshopAdult: boolean;
  workshopAdultsNrOf: number;
  workshopYoga: boolean;
  workshopYogaNrOf: number;
  confirmPayment?: boolean | undefined;
}

const Selection = styled.div`
  margin-bottom: 8px;
  padding: 8px;

  &.active {
    border: 1px solid var(--green);
    background-color: var(--green-20);

    .selection-item {
      font-weight: bold;
      margin-bottom: 16px;
    }
  }
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-gap: 16px;
  align-items: center;
`;
const Actions = styled.div`
  text-align: right;
`;

const AmountContainer = styled.div`
  margin: 16px 0;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 4px 16px;

  div:nth-child(odd) {
    text-align: right;
  }

  div:nth-child(even) {
    //font-weight: bold;
  }
`;

const SignupFormWrapper = styled(FormWrapper)`
  .p-inputnumber {
    .p-inputtext {
      width: 60px;
      text-align: center;
    }
  }
`;

const OptionLabel = styled.div`
  > *:last-child {
    font-size: 75%
  }
`;

const Listing = styled.div`
  margin: 16px 0;
  border: 1px solid var(--green);
  padding: 16px;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 4px 16px;

  .label {
    text-align: right;
    font-weight: bold;
  }
`;

const SignupForm = (props: SignupFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [email] = useQueryParam('email', StringParam);

  let initialState = {
    name: '',
    firstName: '',
    email: '',
    phone: '',
    member: false,
    membershipId: undefined,
    buffet: false,
    buffetSlot: 2,
    buffetNrOfAdults: 2,
    buffetNrOfChildren: 0,
    buffetNrOfChildrenFree: 0,
    workshopChildren: false,
    workshopAdult: false,
    workshopAdultsNrOf: 0,
    workshopChildrenNrOf: 0,
    workshopYoga: false,
    workshopYogaNrOf: 0,
    confirmPayment: undefined,
  };
  const [signupData, setSignupData] = useState<SignupData>(initialState);

  const [formikValues, setFormikValues] = useState<SignupData>(initialState);
  const [totals, setTotals] = useState<{
    buffet: number,
    workshopAdults: number,
    total: number,
    workshopChildren: number,
    workshopYoga: number
  }>({
    buffet: 0,
    workshopAdults: 0,
    workshopChildren: 0,
    workshopYoga: 0,
    total: 0,
  });
  const [findClient, {data}] = useLazyQuery<FindClient>(FindClientQuery);
  const [createOogstfeestRegistration] = useMutation<CreateOogstfeestRegistration>(CreateOogstfeestRegistrationMutation);
  const handleSubmit = (values: SignupData, actions: FormikHelpers<SignupData>) => {
    const {
      phone,
      buffetNrOfAdults,
      buffetNrOfChildren,
      name,
      email,
      firstName,
      buffetNrOfChildrenFree,
      workshopChildrenNrOf,
      workshopChildren,
      workshopAdultsNrOf,
      workshopAdult,
      workshopYogaNrOf,
      workshopYoga,
      buffetSlot,
      buffet,
      membershipId,
    } = signupData;
    if (name && firstName && email && phone) {
      let variables: CreateOogstfeestRegistrationVariables = {
        data: {
          name: name,
          firstName: firstName,
          email: email,
          phone: phone,
          membershipId,
          buffetSlot: buffet ? buffetSlot : 0,
          buffetAdults: buffet ? buffetNrOfAdults : 0,
          buffetChildren: buffet ? buffetNrOfChildren : 0,
          buffetFreeChildren: buffet ? buffetNrOfChildrenFree : 0,
          workshopChildren: workshopChildren ? workshopChildrenNrOf : 0,
          workshopYoga: workshopYoga ? workshopYogaNrOf : 0,
          workshopAdults: workshopAdult ? workshopAdultsNrOf : 0,
        }
      };
      createOogstfeestRegistration(
        {
          variables: {...variables}
        }
      )
        .then(r => props.onHide(true))
        .catch((reason) => {
          emailjs.send('service_8ch8mgd', 'template_baitgh7', {
            to: signupData.email,
            name: signupData.firstName,
            reason: JSON.stringify(reason),
          })
            .then((result) => {
              props.onHide(false, result.text);
            }, (error) => {
              //setSending(false);
            });
        });
    }
  }

  useEffect(() => {
    if (email && email.indexOf('@') > -1) {
      findClient({
        variables: {
          email: email.toLowerCase().trim()
        }
      });
    }
  }, [email]);

  useEffect(() => {
    let client = first(data?.clients);
    if (client) {
      const {name, firstName, email, phone, id} = client;
      setSignupData(value => {
        return ({
          ...value,
          name: name || undefined,
          firstName: firstName || undefined,
          email,
          phone: phone || undefined,
          member: true,
          membershipId: id
        });
      });
    }
    if (!client) {
      setSignupData(value => ({...value, member: false, membershipId: undefined}));
    }
  }, [data]);

  useEffect(() => {
    let buffet = 0;
    if (signupData.buffet) {
      buffet += signupData.buffetNrOfAdults * oogstfeestOptions.buffet.adults;
      buffet += signupData.buffetNrOfChildren * oogstfeestOptions.buffet.children;
    }

    let workshopChildren = 0;
    if (signupData.workshopChildren) {
      let price = oogstfeestOptions.workshopChildren.nonMembers;
      if (signupData.member) {
        price = oogstfeestOptions.workshopChildren.members;
      }
      workshopChildren += signupData.workshopChildrenNrOf * price;
    }

    let workshopYoga = 0;
    if (signupData.workshopYoga) {
      let price = oogstfeestOptions.workshopYoga.nonMembers;
      if (signupData.member) {
        price = oogstfeestOptions.workshopYoga.members;
      }
      workshopYoga += signupData.workshopYogaNrOf * price;
    }

    let workshopAdults = 0;
    if (signupData.workshopAdult) {
      let price = oogstfeestOptions.workshopAdults.nonMembers;
      if (signupData.member) {
        price = oogstfeestOptions.workshopAdults.members;
      }
      workshopAdults += signupData.workshopAdultsNrOf * price;
    }


    setTotals({
      buffet, workshopChildren, workshopAdults, workshopYoga, total: buffet + workshopAdults + workshopChildren + workshopYoga
    });
  }, [signupData]);

  return <Dialog
    visible
    onHide={() => props.onHide(false)}
    closeOnEscape
    resizable={false}
    style={{maxWidth: '600px'}}
    header={'Inschrijving oogstfeest'}
  >
    <Formik
      initialValues={formikValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({isSubmitting, isValid, submitCount, errors}) => {
        return (
          <Form id={'signupForm'}>
            <SignupFormWrapper>
              <p><strong>Ledenkorting:</strong> Lid van Groentegeweld? Gebruik dan zeker het e-mailadres waarmee je
                ingeschreven bent om van je ledenkorting te genieten.</p>
              <FormSection>
                <div className={'label'}>E-mailadres</div>
                <FormInput
                  name={'email'}
                  placeholder={'E-mailadres'}
                  value={signupData.email || ''}
                  onChange={(value) => setSignupData(data => ({...data, email: value}))}
                  onBlur={(value) => {
                    if (value && value.indexOf('@') > -1) {
                      findClient({
                        variables: {
                          email: value.toLowerCase().trim()
                        }
                      });
                    }
                  }}
                />

                <div className={'label'}>Naam</div>
                <FormInput
                  name={'firstName'}
                  placeholder={'Voornaam'}
                  onChange={(value) => setSignupData(data => ({...data, firstName: value || undefined}))}
                  value={signupData.firstName}
                />
                <div className={'label'}>Achternaam</div>
                <FormInput
                  name={'name'}
                  placeholder={'Achternaam'}
                  onChange={(value) => setSignupData(data => ({...data, name: value || undefined}))}
                  value={signupData.name}
                />

                <div className={'label'}>GSM / Tel.</div>
                <FormInput
                  name={'phone'}
                  placeholder={'GSM / Tel.'}
                  value={signupData.phone}
                  onChange={(value) => setSignupData(data => ({...data, phone: value || undefined}))}
                />
              </FormSection>
              <h2>Ik schrijf me in voor:</h2>
              <Selection className={classNames({active: signupData.buffet})}>
                <div className={'selection-item'}>

                  <FormCheckbox
                    disabled={true}
                    name={'buffet'}
                    value={signupData.buffet}
                    onChange={(value) => setSignupData(data => ({...data, buffet: value}))}
                    label={'Grazing Table'}
                    // extra={() => }

                  />
                  <div><span className={'p-error'}>VOLZET: </span> <span
                    style={{fontSize: '90%', fontStyle: 'italic'}}>Stuur ons een <a
                    href="mailto:info@groentegeweld.be">mailtje</a> en we bekijken wat er nog mogelijk is.</span>
                  </div>
                </div>
                <div>
                  {signupData.buffet && <Options>
                    <div style={{gridColumn: '1 / span 2'}}>
                      <OptionLabel>
                        <div>Kies jouw uur:</div>
                        <div>(Kom zeker op tijd om ten volle van het buffet te kunnen genieten.)</div>
                      </OptionLabel>
                      <SelectButton
                        disabled={true}
                        value={signupData.buffetSlot}
                        onChange={(e: any) => setSignupData(data => ({...data, buffetSlot: e.value}))}
                        options={[
                          {
                            label: '17u00 - 18u30',
                            value: 1,
                            disabled: true,
                          },
                          {
                            label: '18u45 - 20u15',
                            value: 2,
                            disabled: true,
                          }
                        ]}
                      />
                      <div><span className={'p-error'}>VOLZET: </span> <span
                        style={{fontSize: '90%', fontStyle: 'italic'}}>Stuur ons een <a
                        href="mailto:info@groentegeweld.be">mailtje</a> en we bekijken wat er nog mogelijk is.</span>
                      </div>

                    </div>
                    <OptionLabel>
                      <div>Aantal personen</div>
                      <div>({formatCurrency(oogstfeestOptions.buffet.adults)} per persoon)</div>
                    </OptionLabel>
                    <InputNumber
                      id="stacked"
                      value={signupData.buffetNrOfAdults}
                      className={'buffetNrOfAdults'}
                      onValueChange={(e: any) => setSignupData(data => ({...data, buffetNrOfAdults: e.value}))}
                      showButtons
                      min={1}
                      buttonLayout="horizontal"
                      incrementButtonIcon={'pi pi-plus'}
                      decrementButtonIcon={'pi pi-minus'}
                      maxFractionDigits={0}
                      minFractionDigits={0}
                      step={1}
                    />
                    <OptionLabel>
                      <div>Aantal kinderen van 3 t.e.m. 11 jaar</div>
                      <div>({formatCurrency(oogstfeestOptions.buffet.children)} per kind)</div>
                    </OptionLabel>
                    <InputNumber
                      id="stacked"
                      value={signupData.buffetNrOfChildren}
                      className={'buffetNrOfChildren'}
                      onValueChange={(e: any) => setSignupData(data => ({...data, buffetNrOfChildren: e.value}))}
                      showButtons
                      min={0}
                      buttonLayout="horizontal"
                      incrementButtonIcon={'pi pi-plus'}
                      decrementButtonIcon={'pi pi-minus'}
                      maxFractionDigits={0}
                      minFractionDigits={0}
                      step={1}
                    />
                    <OptionLabel>
                      <div>Aantal kinderen jonger dan 3 jaar</div>
                      <div>(Gratis)</div>
                    </OptionLabel>
                    <InputNumber
                      id="stacked"
                      value={signupData.buffetNrOfChildrenFree}
                      className={'buffetNrOfChildrenFree'}
                      onValueChange={(e: any) => setSignupData(data => ({...data, buffetNrOfChildrenFree: e.value}))}
                      showButtons
                      min={0}
                      buttonLayout="horizontal"
                      incrementButtonIcon={'pi pi-plus'}
                      decrementButtonIcon={'pi pi-minus'}
                      maxFractionDigits={0}
                      minFractionDigits={0}
                      step={1}
                    />
                  </Options>}
                </div>
              </Selection>

              <Selection className={classNames({active: signupData.workshopYoga})}>
                <div className={'selection-item'}>
                  <FormCheckbox
                    name={'workshopYoga'}
                    value={signupData.workshopYoga}
                    onChange={(value) => setSignupData(data => ({...data, workshopYoga: value}))}
                    label={'Yoga op het veld'}
                  />
                </div>
                <div>
                  {signupData.workshopYoga && <Options>
                    <OptionLabel>
                      <div>Aantal personen</div>
                      <div>({formatCurrency(oogstfeestOptions.workshopYoga[signupData.member ? 'members' : 'nonMembers'])} per
                        persoon)
                      </div>
                    </OptionLabel>
                    <InputNumber
                      id="stacked"
                      value={signupData.workshopYogaNrOf}
                      className={'workshopYogaNrOf'}
                      onValueChange={(e: any) => setSignupData(data => ({...data, workshopYogaNrOf: e.value}))}
                      showButtons
                      min={1}
                      buttonLayout="horizontal"
                      incrementButtonIcon={'pi pi-plus'}
                      decrementButtonIcon={'pi pi-minus'}
                      maxFractionDigits={0}
                      minFractionDigits={0}
                      step={1}
                    /></Options>}
                </div>
              </Selection>

              <Selection className={classNames({active: signupData.workshopAdult})}>
                <div className={'selection-item'}>
                  <FormCheckbox
                    name={'workshopAdult'}
                    value={signupData.workshopAdult}
                    onChange={(value) => setSignupData(data => ({...data, workshopAdult: value}))}
                    label={'Culinaire wandeling'}
                  />
                </div>
                <div>
                  {signupData.workshopAdult && <Options>
                    <OptionLabel>
                      <div>Aantal personen</div>
                      <div>({formatCurrency(oogstfeestOptions.workshopAdults[signupData.member ? 'members' : 'nonMembers'])} per
                        persoon)
                      </div>
                    </OptionLabel>
                    <InputNumber
                      id="stacked"
                      value={signupData.workshopAdultsNrOf}
                      className={'workshopAdultsNrOf'}
                      onValueChange={(e: any) => setSignupData(data => ({...data, workshopAdultsNrOf: e.value}))}
                      showButtons
                      min={1}
                      buttonLayout="horizontal"
                      incrementButtonIcon={'pi pi-plus'}
                      decrementButtonIcon={'pi pi-minus'}
                      maxFractionDigits={0}
                      minFractionDigits={0}
                      step={1}
                    /></Options>}
                </div>
              </Selection>

              <Selection className={classNames({active: signupData.workshopChildren})}>
                <div className={'selection-item'}>
                  <FormCheckbox
                    name={'workshopChildren'}
                    value={signupData.workshopChildren}
                    onChange={(value) => setSignupData(data => ({...data, workshopChildren: value}))}
                    label={'Proevertjespad'}
                  />
                </div>
                <div>
                  {signupData.workshopChildren && <Options>
                    <OptionLabel>
                      <div>Aantal kinderen van 4 t.e.m. 12 jaar</div>
                      <div>({formatCurrency(oogstfeestOptions.workshopChildren[signupData.member ? 'members' : 'nonMembers'])} per
                        kind)
                      </div>
                    </OptionLabel>
                    <InputNumber
                      id="stacked"
                      value={signupData.workshopChildrenNrOf}
                      className={'workshopChildrenNrOf'}
                      onValueChange={(e: any) => setSignupData(data => ({...data, workshopChildrenNrOf: e.value}))}
                      showButtons
                      min={1}
                      buttonLayout="horizontal"
                      incrementButtonIcon={'pi pi-plus'}
                      decrementButtonIcon={'pi pi-minus'}
                      maxFractionDigits={0}
                      minFractionDigits={0}
                      step={1}
                    />
                  </Options>}
                </div>
              </Selection>

              <AmountContainer>
                {signupData.buffet && <>
                  <div>Grazing table:</div>
                  <div>{formatCurrency(totals.buffet)}</div>
                </>}
                {signupData.workshopYoga && <>
                  <div>Yoga op het veld:</div>
                  <div>{formatCurrency(totals.workshopYoga)}</div>
                </>}
                {signupData.workshopAdult && <>
                  <div>Culinaire wandeling:</div>
                  <div>{formatCurrency(totals.workshopAdults)}</div>
                </>}
                {signupData.workshopChildren && <>
                  <div>Proevertjespad:</div>
                  <div>{formatCurrency(totals.workshopChildren)}</div>
                </>}
                <div><strong>Totaal:</strong></div>
                <div><strong>{formatCurrency(totals.total)}</strong></div>
              </AmountContainer>
              <Listing>
                <div style={{gridColumn: '1 / span 2'}}>
                  <p>
                    Je inschrijving is pas geldig als wij je betaling hebben ontvangen.
                  </p>
                </div>
                <div className={'label'}>Rekeningnummer</div>
                <div>BE46 5230 8118 5136</div>
                <div className={'label'}>Mededeling</div>
                <div>Oogstfeest {signupData.name} {signupData.firstName}</div>
                <div className={'label'}>Bedrag</div>
                <div>{formatCurrency(totals.total)}</div>
                <div className="label">Bevestiging</div>
                <div>
                  <FormCheckbox
                    name={'confirmPayment'}
                    value={!!signupData.confirmPayment}
                    onChange={(value) => setSignupData(data => ({...data, confirmPayment: value}))}
                    label={'Ik bevestig mijn betaling te hebben uitgevoerd.'}
                  />
                </div>
              </Listing>
              <Actions>
                <div>
                  {!isValid && submitCount > 0 && errors &&
                    <span style={{color: '#a80000'}}>Gelieve alle verplichte velden in te vullen.</span>}
                </div>
                <div>
                  <Button
                    disabled={isSubmitting}
                    type={'button'}
                    className={'p-button-link'}
                    label={'Annuleren'}
                    onClick={() => props.onHide(false)}
                  />
                  <Button
                    disabled={isSubmitting || (!signupData.buffet && !signupData.workshopAdult && !signupData.workshopChildren)}
                    loading={isSubmitting} iconPos={'right'}
                    type={'submit'}
                    label={'Inschrijving bevestigen'}
                  />
                </div>
              </Actions>
            </SignupFormWrapper>
          </Form>
        );
      }}
    </Formik>
  </Dialog>;
};

export default SignupForm;
