import { boolean, object, string } from "yup";

export const validationSchema =
  object().shape({
    'firstName': string().label('Voornaam').required(),
    'name': string().label('Achternaam').required(),
    'email': string().label('E-mailadres').required().email('Het opgegeven e-mailadres is niet geldig'),
    'phone': string().label('GSM-nummer').required(),
    'confirmPayment': boolean().test('Betaling', 'Betaling is verplicht', x => x === true),
  });
