import { Grid } from 'pages/home';
import React, { useRef, useState } from 'react';
import { Content, FullImage } from "../../App";
import { Galleria } from 'primereact/galleria';
import { Button } from "primereact/button";
import SignupForm from "../OogstfeestPage/SignupForm";
import SFPage from 'shared/components/soft-farms-bootstrap';
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { oogstfeestOptions } from "../../shared/config.oogstfeest";

const responsiveOptions = [
  {
    breakpoint: '1024px',
    numVisible: 5
  },
  {
    breakpoint: '768px',
    numVisible: 3
  },
  {
    breakpoint: '560px',
    numVisible: 1
  }
];

const Container = styled(SFPage)`
  h2 {
    font-weight: bold;
    margin-top: 16px;
    line-height: 1em;

    span {
      font-size: 70%;
      color: var(--green);
    }
  }

  .dinner-block::before {
    position: absolute;
    content: 'x';
    top: 0;
    left: 0;
    right: 0;
    background-image: url('/lekkerstefeest-line-bottom.gif');
  }
`;

const itemTemplate = (item: any) => {
  return <img
    src={`${item.itemImageSrc}`}
    onError={(e) => {
    }}
    alt={item.alt}
    style={{width: '100%'}}
  />;
}

const thumbnailTemplate = (item: any) => {
  return <img
    src={`${item.thumbnailImageSrc}`}
    onError={(e) => {
    }}
    alt={item.alt}
  />;
}

const Oogstfeest2024Page = () => {
  const [showSignupForm, setShowSignupForm] = useState<boolean>(false);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const toast = useRef<Toast>(null);

  return <Container>
    <Toast ref={toast} position={'top-left'}/>

    <img className="w-full" src={'lekkerstefeest-min.png'}/>

    <Content style={{marginTop: '20px'}}>
      {showSignupForm && <SignupForm onHide={(registrationComplete, error) => {
        setShowSignupForm(false);
        if (registrationComplete) {
          setShowConfirmationMessage(true);
        }
        if (error) {
          setShowErrorMessage(true);
        }
      }}/>}
      {showConfirmationMessage && <Dialog
        visible
        onHide={() => setShowConfirmationMessage(false)}
        closeOnEscape
        resizable={false}
        header={<div className="coor-orange color-orange text-2xl">Inschrijving gelukt</div>}
        footer={<div>
          <Button label={'Ok'} onClick={() => setShowConfirmationMessage(false)}/>
        </div>}
      >
        <div className="text-xl font-medium color-green">Je inschrijving is gelukt. <br/>Tot op het lekkerste feest!</div>
      </Dialog>}

      {showErrorMessage && <Dialog
        visible
        onHide={() => setShowErrorMessage(false)}
        closeOnEscape
        resizable={false}
        header={'Inschrijving niet gelukt'}
        footer={<div>
          <Button label={'Ok'} onClick={() => setShowErrorMessage(false)}/>
        </div>}

      >
        <p style={{color: 'var(--error)', fontWeight: 'bold'}}>Er ging iets mis. Probeer nogmaals in te schrijven of
          contacteer ons als het probleem zich blijft voortdoen.</p>
      </Dialog>}

      <div>
        <h2>Groentegeweld viert feest!</h2>
        <p>Naar jaarlijkse gewoonte vieren we feest, het is namelijk oogstmaand.
          Iedereen is dan ook welkom op ons feest in de lekkerste buurt!</p>
        <Button
          label={'Ik schrijf mij in!'}
          type={"button"}
          onClick={() => {
            setShowSignupForm(true);
          }}
        />
        <br/>
        <br/>
        <h2>Programma</h2>
        <ul>
          <li>Eten en drinken: Grazing Table</li>
          <li>Muziek: One man Band Fik Dries</li>
          <li>Activiteit: yoga & culinaire wandeling op het veld</li>
          <li>Kinderen: springkasteel, proevertjespad</li>
        </ul>
        <p><span className="font-bold">OPGELET:</span> Voor de activiteiten en het dinner moet je <span
          className="color-orange font-bold">vooraf inschrijven</span>.</p>
      </div>
    </Content>

    <img className="w-full" src={'lekkerstefeest-line-top.gif'}/>

    <Content style={{zIndex: 10}}>
      <Grid columns={'3fr 2fr'}>
        <div className="align-self-center">
          <h1 style={{marginBottom: 0}}>Veldterras <span style={{fontWeight: "normal", paddingLeft: "8px"}}>vanaf 14u</span></h1>
          <div className="color-orange uppercase mb-2">(Vrije toegang)</div>
          <p>Iedereen is welkom op ons veldterras. Geniet van een streekbier, een veldcocktail en heerlijke apero hapjes
            van het veld! <br/>Breng zeker de kinderen mee en laat hen de zandbak, het springkasteel en het veld
            ontdekken.</p>
        </div>
        <img className="w-full" src={'lekkerstefeest-veldterras.jpg'}/>
      </Grid>
    </Content>

    <img className="w-full" src={'lekkerstefeest-line-bottom.gif'}/>

    <Content style={{zIndex: 9}}>
      <div className="dinner-block relative">
        <Grid columns={'2fr 3fr'}>
          <img src={'/oogstfeest/buffet.jpeg'} className="w-full"/>
          <div className="align-self-center">
            <h1 style={{marginBottom: 0}}>Grazing Table <span style={{fontWeight: "normal", paddingLeft: "8px"}}>vanaf 17u</span>
            </h1>
            <div className="color-orange uppercase mb-2">(Inschrijven verplicht)</div>
            <p>Het team van Rimbaud/Arthur en Broosend Hof verzorgen een heuse Grazing Table; graas door het rijkelijke
              buffet met uiteraard zoveel mogelijk producten
              recht van het veld. Ook de overige ingrediënten proberen we lokaal aan te kopen!<br/>
              Ook dit jaar zal de houtoven overuren draaien met de lekkerste pizza's uit de buurt.
            </p>
            <div className="color-brown mb-2">
              <p>
                <i>{oogstfeestOptions.buffet.adults} euro per persoon <br/>
                  {oogstfeestOptions.buffet.children} euro voor kinderen van 3 t.e.m. 11 jaar <br/>
                  Gratis voor kinderen jonger dan 3 jaar</i>
              </p>
            </div>
            <div className="m-1 font-bold "><span className={'p-error'}>VOLZET: </span> <span
              style={{fontStyle: 'italic'}}>Stuur ons een <a
              href="mailto:info@groentegeweld.be">mailtje</a> en we bekijken wat er nog mogelijk is.</span>
            </div>
            <Button
              disabled={true}
              outlined
              label={'Inschrijven voor het buffet'}
              onClick={() => {
                setShowSignupForm(true);
              }}
            />
          </div>
        </Grid>
      </div>
    </Content>

    <img className="w-full" src={'lekkerstefeest-line-bottom.gif'}/>

    <Content>
      <div>
        <h1 style={{marginBottom: 0}}>Activiteiten<span
          style={{fontWeight: "normal", paddingLeft: "8px"}}>vanaf 14u</span></h1>
        <div className="color-orange uppercase mb-2">(Inschrijven verplicht)</div>

        <Grid columns={'2fr 1fr'}>
          <div>
            <h2>Yoga op het veld</h2>
            <p>Do-In Yoga buiten, tussen de groenten, fruitstruiken en bloemen en planten?
              Mooier gaat het niet worden. <br/>
              Ontspannen bewegen, luisteren naar je lichaam, zonder oordeel en ondertussen genieten van de geuren die je omringen.
              Iedereen van harte welkom. Voor de één kan het verdieping zijn, voor de ander een eerste kennismaking.
              Belangrijk is om open te staan voor dat wat er op dat moment gebeurt bij en met jou. <br/>
              Graag tot dan.<br/>
            </p>
            <div className="color-brown mb-2">
              <i>Leden: 5 euro <br/>
                Niet-leden: 7,5 euro</i>
            </div>
            <Button outlined label={"Inschrijven"} onClick={() => setShowSignupForm(true)}></Button>
          </div>
          <img style={{width: '100%'}} src="lekkerstefeest-yoga.jpg"/>
        </Grid>
        <Grid columns={'3fr 3fr'} width={150} className="mt-4" style={{gridGap: "4vh 8vw"}}>
          <div>
            <img style={{width: '100%'}} src="lekkerstefeest-wandeling.jpg"/>
            <h2>Culinaire wandeling</h2>
            <p>In de namiddag geeft Queen Katrien een culinaire rondleiding over het
              veld. Ze geeft tijdens de rondleiding
              creatieve tips over het gebruik, bewaring en verwerking van alle groenten: van bewaring in je ijskast tot
              wecken, van wokken tot grillen! Alles komt aan bod. <br/>
            </p>
            <div className="color-brown mb-2">
              <i>Leden: 5 euro <br/>
                Niet-leden: 7,5 euro</i>
            </div>
            <Button outlined label={"Inschrijven"} onClick={() => setShowSignupForm(true)}></Button>
          </div>
          <div>
            <img style={{width: '100%'}} src="lekkerstefeest-proevertjespad.jpg"/>
            <h2>Proevertjespad</h2>
            <p> Kinderen mogen springen op het springkasteel, maar ze kunnen ook op proevertjespad met boer Dirk en
              Willem. Ze gaan op
              ontdekking. En uiteraard doen kinderen dat graag al proevend, hmmmm!<br/>
            </p>
            <div className="color-brown mb-2">
              <i>Leden: 3 euro <br/>
                Niet-leden: 5 euro</i>
            </div>
            <Button outlined label={"Inschrijven"} onClick={() => setShowSignupForm(true)}></Button>
          </div>
        </Grid>
      </div>
    </Content>

    <img className="w-full" src={'lekkerstefeest-line-bottom.gif'}/>

    <Content style={{paddingBottom: "50px"}}>
      <div>
        <h1>Optredens en afterparty met DJ <br/><span style={{fontWeight: "normal"}}>(vanaf 20u30, gratis inkom)</span>
        </h1>
        <p>'s Avonds houden we het niet droog: we houden de toog graag open en daarbij mag muziek niet ontbreken: rond een uur of negen worden we verwend met een live optreden en we sluiten de avond af met een dikke afterparty met DJ!</p>
      </div>

      <div>
        <img className="w-full" src={'lekkerstefeest-optreden.jpg'}/>
      </div>

    </Content>
  </Container>;
};

export default Oogstfeest2024Page;
