export interface OogstfeestOptions {
  buffet: {
    adults: number
    children: number
  },
  workshopAdults: {
    members: number,
    nonMembers: number,
  },
  workshopChildren: {
    members: number,
    nonMembers: number,
  },
  workshopYoga: {
    members: number,
    nonMembers: number,
  },
}

export const oogstfeestOptions: OogstfeestOptions = {
  buffet: {
    adults: 28,
    children: 15,
  },
  workshopAdults: {
    members: 5,
    nonMembers: 7.5,
  },
  workshopChildren: {
    members: 3,
    nonMembers: 5,
  },
  workshopYoga: {
    members: 5,
    nonMembers: 7.5,
  },
}
